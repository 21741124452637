import { render, staticRenderFns } from "./SectionDetails.vue?vue&type=template&id=342da5b2&scoped=true&"
import script from "./SectionDetails.vue?vue&type=script&lang=ts&"
export * from "./SectionDetails.vue?vue&type=script&lang=ts&"
import style0 from "./SectionDetails.vue?vue&type=style&index=0&id=342da5b2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "342da5b2",
  null
  
)

export default component.exports