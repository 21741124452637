import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        description: {
            type: String,
            default: ''
        },
        venueName: {
            type: String,
            default: ''
        },
        cityName: {
            type: String,
            default: ''
        }
    }
});
