import VenueDetailInfo from '@/pages/customer/Venue/VenueDetailInfo.vue';
import WeekWorkTime from '@/Venue/components/WeekWorkTime.vue';
import { formatWorkingTime, getItemLocalStorage } from '@/util/utils';
import { defineComponent, computed, ref, onMounted } from '@vue/composition-api';
import { VenueChatDocument, UserRoleState } from '@/generated-types/graphql.types';
import { apolloClient } from '@/main';
import { ChatRoutes } from '@/Chat/chat.const';
import { useUserDetailsCompleted } from '@/shared/composables/useUserDetailsCompleted';
import { saveCurrentPath } from '@/util/utils';
import PreSaveActionModal from '@/shared/components/PreSaveActionModal.vue';
import { PRE_SAVE_ACTION_TYPES, preSaveAction, resetPreSaveAction } from '@/util/preSaveActions';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import SectionSpaces from '@/Venue/components/SectionSpaces.vue';
import { toCamelCase } from '@/util/transform';
import { OrganisationRoutes } from '@/Organisation/organisation.const';
import { GeoPagesRoutes } from '@/GeoPages/geopages.const';
export default defineComponent({
    components: {
        WeekWorkTime,
        VenueDetailInfo,
        PreSaveActionModal,
        SectionSpaces
    },
    props: {
        venue: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, context) {
        const { root } = context;
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const { performAccountFlowAction } = useUserDetailsCompleted(root);
        const hasUnfinishedAction = ref(false);
        const unfinishedActionType = ref('');
        const spaces = computed(() => props.venue.venue_spaces?.map(item => {
            const space = {
                ...item,
                images: item.space_images.map(el => el.image_url),
                booking_type: item.space_booking_type,
                favorite: item.is_favorite,
                activities: item.space_categories.map(el => el.category_id),
                features: item.space_features.map(el => el.feature_id),
                venueName: props.venue?.venue_name,
                spaceSize: item.space_size,
                isAfterpayAllowed: item.is_afterpay_allowed,
                attendees: {
                    minGuests: item.min_guests,
                    maxGuests: item.max_guests
                },
                isHighlighted: item.is_highlighted,
                organisationRating: props.venue.organisation_rating?.score || 0,
                isNew: item.is_new
            };
            return toCamelCase(space);
        }));
        const openChat = (venue) => {
            resetPreSaveAction();
            return async () => {
                const { data, errors } = await apolloClient.query({
                    query: VenueChatDocument,
                    variables: { id: venue.venue_id },
                    fetchPolicy: 'no-cache'
                });
                if (!errors && data?.get_chat) {
                    globalThis.$router.push(useGetLocalizedPath(`${ChatRoutes.CHAT}/${data.get_chat.channel_id}`));
                }
            };
        };
        const createChat = (venue) => {
            // Callback for unauthorized users
            if (performAccountFlowAction.value) {
                preSaveAction({ type: PRE_SAVE_ACTION_TYPES.CHAT, venue: venue });
                saveCurrentPath(root.$route.fullPath.slice(1));
                performAccountFlowAction.value(UserRoleState.Booker);
                return;
            }
            // Send event
            globalThis.$gtm.trackEvent({
                event: 'venue_chat_opened',
                category: 'consideration',
                action: 'venue_chat_opened',
                label: 'venue_chat_opened',
                value: venue.venue_name,
                noninteraction: false
            });
            openChat(venue)();
        };
        const workTime = computed(() => formatWorkingTime(props.venue.working_time));
        const continuePreSaveAction = () => {
            if (performAccountFlowAction.value) {
                performAccountFlowAction.value(UserRoleState.Booker);
                return;
            }
            let preSavedType = localStorage.getItem('preSavedType');
            let preSavedVenue = getItemLocalStorage('preSavedVenue');
            if (preSavedType === PRE_SAVE_ACTION_TYPES.CHAT && preSavedVenue) {
                openChat(preSavedVenue)();
            }
        };
        const cancelPreSaveSpaceAction = () => {
            hasUnfinishedAction.value = false;
            unfinishedActionType.value = '';
            resetPreSaveAction();
        };
        onMounted(() => {
            const preSavedType = localStorage.getItem('preSavedType');
            if (preSavedType && preSavedType === PRE_SAVE_ACTION_TYPES.CHAT) {
                hasUnfinishedAction.value = true;
                unfinishedActionType.value = preSavedType;
            }
        });
        return {
            hasUnfinishedAction,
            spaces,
            unfinishedActionType,
            user,
            workTime,
            GeoPagesRoutes,
            OrganisationRoutes,
            UserRoleState,
            cancelPreSaveSpaceAction,
            continuePreSaveAction,
            createChat,
            useGetLocalizedPath
        };
    }
});
