import { capitalize } from 'lodash';
import { SITE_URL } from '@/App.vue';
import { reFormatTime } from '@/util/utils';
import { enumDayOfWeek } from '@/util/dates';
export const venueSchemaOrgInfo = (venue) => {
    const advertisedImage = venue.venue_images[0]?.image_url;
    const images = venue.venue_images?.map(image => image?.image_url);
    const geo = venue.venue_geo;
    const address = {
        '@type': 'PostalAddress',
        streetAddress: geo.address,
        addressLocality: geo.city,
        addressRegion: geo.region,
        postalCode: geo.postal_code
    };
    const openingHours = venue.working_time?.map(workingDay => ({
        '@type': 'OpeningHoursSpecification',
        // @ts-ignore
        dayOfWeek: capitalize(enumDayOfWeek.get(workingDay.week_day)),
        // @ts-ignore
        opens: reFormatTime(workingDay.open_time),
        // @ts-ignore
        closes: reFormatTime(workingDay.close_time)
    }));
    return {
        '@type': ['Place', 'LocalBusiness'],
        name: venue.venue_name,
        description: venue.venue_description,
        photo: advertisedImage,
        image: images,
        geo: {
            '@type': 'GeoCoordinates',
            latitude: geo.latitude,
            longitude: geo.longitude
        },
        address: address,
        location: address,
        url: `${SITE_URL}/customer/venue${venue.venue_url}`,
        email: venue.contact_email,
        telephone: venue.contact_phone,
        branchCode: venue.venue_slug,
        openingHoursSpecification: openingHours
    };
};
